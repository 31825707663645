<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Email Subscription Status Report"
    >
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            View Email Subscription Status Report
          </div>
        </div>
      </template>
    </PageHeader>
    <validation-observer ref="reportForm">
      <v-row class="form-container">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="segmentSelection"
            header="Segment"
            :items="segments"
          />
        </v-col>
      </v-row>
    </validation-observer>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="submit"
        >
          Generate Report
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "EmailSubscriptionStatusReport",
  components: { CustomDropdown, PageHeader },
  data: () => ({
    segments: [
      { value: null, label: 'All Contacts (No Segment)' },
    ],
    segmentSelection: null,
  }),
  created() {
    this.loadSegment();
  },
  methods: {
    async loadSegment() {
      const response = await this.$rest.segment.get_collection({
        ignorePagination: true,
        sort: ['name:asc'],
      });

      if (!response.data) {
        return;
      }

      response.data.items.forEach((item) => {
        this.segments.push({
          value: item.id,
          label: item.name,
        });
      });
    },
    async submit() {
      await this.$rest.exports.post_resource({
        "exportType": "email_subscription_status",
        "exportData": {
          "segmentId": this.segmentSelection,
        },
      });

      await this.$router.push({ name: "ExportsView" });
    },
  },
}
</script>

<style scoped>
.form-container {
  margin-top: 40px;
}
</style>